<template>
  <v-card :class="addClass+ ' my-5'">
    <v-card-title>
      Debug
    </v-card-title>
    <v-card-text>
      <v-sheet
          dark
          class="pa-5"
      >
        <template v-for="(obj, index) in objs" >
          <template v-for="(value, key) in obj">
            <p :key="key+index">
              <strong class="text-uppercase">{{key}}</strong><br>
              <vue-json-pretty
                  :data="value"
                  :deep="0"
                  :showLength="true"
              >
              </vue-json-pretty>
            </p>
          </template>
        </template>
      </v-sheet>
    </v-card-text>
  </v-card>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';

export default {
  name: "ObjDebug",
  components: {VueJsonPretty},
  props: {
    addClass: {
      type: String
    },
    objs: {
      type: Array,
      required: true
    }
  },
}
</script>

<style scoped>

</style>